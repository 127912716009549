const React = require('react');
const { shape, arrayOf, string } = require('prop-types');
const { ErrorCodes, VIEW_MODES } = require('../../services/data/enums');

const InputError = ({ errors, schemaType, trackingId, loginType, viewMode, viewName }) => {
  const createTrackIncompleteRegistrationClick = (error) => {
    try {
      window.incompleteRegistrationClickEvent = new CustomEvent('incompleteRegistrationClick');
      document.addEventListener('incompleteRegistrationClick', () => {
        window.melidata('send', 'event', {
          path: '/login/auth/challenge/incomplete_registration',
          event_data: {
            challenge: schemaType,
            tracking_id: trackingId,
            source: loginType,
          },
          user: {
            user_id: error.hints.user_id,
          },
        });
      });
      window.onIncompleteRegistrationClick = () => {
        document.dispatchEvent(window.incompleteRegistrationClickEvent);

        window.location.assign(error.actionUrl);
      };
    } catch (e) {
      return null;
    }

    return null;
  };

  const userIncompleteRegistration = errors.find((error) => error.cause === ErrorCodes.USER_INCOMPLETE_REGISTRATION);
  if (userIncompleteRegistration) {
    createTrackIncompleteRegistrationClick(userIncompleteRegistration);
  }

  return (
    <div className="input-error" role="alert">
      {errors.map(e => (
        <div key={e.cause} className="ui-form__message" id={`${viewName}-message`}>
          {(userIncompleteRegistration && viewMode === VIEW_MODES.landscape)
            ? (<div
                className="ui-form__message--text"
                dangerouslySetInnerHTML={{ __html: e.text }} // eslint-disable-line react/no-danger
            />)
            : e.text}
          {e.cause === ErrorCodes.INVALID_USER_SITE
            ? <a href={e.hints.link}>{e.hints.brand}</a>
            : null}
        </div>
      ))}
    </div>
  );
};

InputError.propTypes = {
  errors: arrayOf(shape()).isRequired,
  schemaType: string,
  trackingId: string,
  loginType: string,
  viewMode: string,
  viewName: string,
};

module.exports = InputError;
