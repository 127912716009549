import { ErrorCodes } from '../data/enums';

export const filterErrors = (errors, wordings, config) => {
  const inputErrors = [];
  const recaptchaErrors = [];
  errors.forEach(({ ...error }) => {
    error.text = wordings.errors[error.cause];

    if (error.cause === ErrorCodes.USER_INCOMPLETE_REGISTRATION) {
      error.actionUrl = config?.actionUrl;
    }

    return error.code === ErrorCodes.RECAPTCHA
      ? recaptchaErrors.push(error)
      : inputErrors.push(error);
  });

  return { input: inputErrors, recaptcha: recaptchaErrors, all: errors };
};

export const joinErrors = (inputErrors = [], recaptchaErrors = []) => {
  let validationErrors = '';
  inputErrors.forEach(error => { validationErrors += `${error.cause},`; });
  recaptchaErrors.forEach(error => { validationErrors += `${error.cause},`; });

  return validationErrors.replace(/,$/, '').toUpperCase();
};

export const createErrorDescriptor = error => {
  const description = { ...error, stack: error.stack };

  if (error?.isAxiosError) {
    const { data, status } = error.response ?? {};

    return {
      ...description,
      status,
      message: data?.message ?? error?.message,
      code: data?.code ?? error?.code,
    };
  }

  return description;
};
