/* globals window, newrelic, meli_ga */
import React, { useState, useCallback } from 'react';
import { bool, string, shape, arrayOf } from 'prop-types';

import { Head } from 'nordic/head';
import Page from 'nordic/page';
import { Script } from 'nordic/script';
import RestClient from 'nordic/restclient';
import { Button } from '@andes/button';
import { Snackbar } from '@andes/snackbar';
import { Form } from '@andes/form';
import Textfield from '@andes/textfield';
import { AndesProvider } from '@andes/context';
import { GridView } from '@auth/grid-view';
import classnames from 'classnames';

import CheckoutGA from '../../../components/CheckoutGA';
import LoginGA from '../../../components/LoginGA';
import StyleAsset from '../../../components/StyleAsset';
import ProfilingScriptContainer from '../../../components/ProfilingScriptContainer';
import Recaptcha from '../../../components/Recaptcha';
import InputError from '../../../components/InputError';
import EmailInput from '../../../components/EmailInput';
import { withMaskedValue } from '../../../components/hooks/textfieldMask';
import RegistrationLink from '../../../components/RegistrationLink';
import ReplaceUrl from '../../../components/ReplaceUrl';
import DomainPreconnect from '../../../components/domain-preconnect/DomainPreconnect';
import { filterErrors, joinErrors } from '../../../../services/utils/errors';
import { ErrorCodes } from '../../../../services/data/enums';
import { SecurityProblem } from '../../../components/SecurityIssues';
import { withNoWrapWord } from '../../../../services/utils/textTools';
import { trackEvent } from '../../../../services/tracks';
import { GoogleAuth } from '@social-auth/google-auth';
import { getGoogleAuthButtonConfig } from '../../../../services/utils/social';

const TextfieldWithMask = withMaskedValue(Textfield);
const restClientConfig = () => ({
  baseURL: window.location.origin,
  headers: {
    Accept: 'application/json',
    ContentType: 'application/json',
  },
  timeout: 10000,
});
const ERROR_SNACKBAR_ANIMATION_DELAY = 3000;

const Identification = (props) => {
  const {
    csrfToken,
    defaultSuggestions,
    device,
    locale,
    errors,
    navigation,
    platform,
    preconnectProps,
    promiseTimeoutError = false,
    profiling = {},
    recaptcha,
    showRegistrationLink,
    schemaType,
    track,
    urls,
    user,
    viewName,
    viewMode,
    contentConfiguration,
    wordings,
    googleButtonWidth,
    googleSocialId,
  } = props;

  const { lowEnd, mobile } = device;
  const { apiCallbackUrl, selfUrl } = urls;
  const { identification = '', usernameVisibility } = user;
  const { id: dps } = profiling;
  const { platform_id, login_type: loginType, parameters: { go } } = navigation;
  const { isSocialLogin } = contentConfiguration ?? {};
  const [inputValue, setInputValue] = useState(identification);
  const [previousInputValue, setPreviousInputValue] = useState(identification);
  const [inputErrors, setInputtErrors] = useState(errors.input);
  const [previousInputErrors, setPreviousInputErrors] = useState(errors.input);
  const [recaptchaErrors, setRecaptchaErrors] = useState(errors.recaptcha);
  const [kstrs, setKstrs] = useState('');
  const [loading, setLoading] = useState(false);
  const [socialGoogleError, setSocialGoogleError] = useState(false);

  const recaptchaProps = {
    ...recaptcha,
    errors: recaptchaErrors,
    lowEnd,
    viewMode,
    wordings: wordings.recaptcha,
  };

  const inputErrorProps = {
    schemaType,
    trackingId: track.id,
    errors: inputErrors,
    loginType,
    viewMode,
    viewName,
  };

  const getFormData = () => {
    const data = {
      user_id: inputValue,
      dps,
      kstrs,
      type: schemaType,
    };
    const recaptchaResponse = document.getElementById('g-recaptcha-response');
    if (recaptchaResponse) {
      data['g-recaptcha-response'] = recaptchaResponse.value;
    }
    const recaptchaToken = document.getElementById('gctkn');
    if (recaptchaToken) {
      data.gctkn = recaptchaToken.value;
    }

    return data;
  };

  const sendDataToGA = () => {
    if (window?.meli_ga) {
      const errorsDescription = joinErrors(inputErrors, recaptchaErrors);
      meli_ga('send', 'event', 'LOGIN', 'USER_IDENTIFICATION_ERROR', errorsDescription);
    }
  };

  const handleChange = (value) => {
    if (value !== previousInputValue) {
      setInputtErrors([]);
    } else {
      setInputtErrors(previousInputErrors);
    }
    if (value !== undefined) {
      setInputValue(value);
    }
  };

  const handleKeyUp = (event) => {
    if (event.which === 17 || event.which === 91) {
      setKstrs(`${kstrs}u${event.which}`);
    }
  };

  const resetRecaptcha = () => {
    if (window.grecaptcha && recaptcha.required) {
      if (window.grecaptcha?.enterprise) {
        window.grecaptcha.enterprise.reset();
      } else {
        window.grecaptcha.reset();
      }
    }
  };

  const handleEmptyInput = () => {
    if (!inputValue) {
      setLoading(false);
      setInputtErrors([{
        text: wordings.errors.user_required,
      }]);
    }

    return !inputValue;
  };

  const submitForm = () => {
    const isEmpty = handleEmptyInput();
    if (isEmpty) return;
    setPreviousInputValue(inputValue);
    const restClient = RestClient(restClientConfig());
    restClient.post(apiCallbackUrl, { data: getFormData() })
      .then((response) => {
        const { redirect_url, errors: apiErrors } = response.data;
        if (apiErrors) {
          const actionUrl = `${urls.incompleteRegistrationUrl}/${inputValue}`;
          const { input: inputErr, recaptcha: recaptchaErr } = filterErrors(apiErrors, wordings, { actionUrl });
          setLoading(false);
          setPreviousInputErrors(inputErr);
          setInputtErrors(inputErr);
          setRecaptchaErrors(recaptchaErr);
          sendDataToGA();
          resetRecaptcha();
          setInputValue(inputValue);
        }
        if (redirect_url) {
          window.location.href = redirect_url;
        }
      })
      .catch((error) => {
        const { input: inputErr } = filterErrors([{ cause: ErrorCodes.SERVER_ERROR }], wordings);
        setLoading(false);
        setInputtErrors(inputErr);
        setInputValue(inputValue);
        if (typeof newrelic !== 'undefined') {
          newrelic.noticeError(new Error(error.response || error.message));
        }
      });
  };

  const onSubmit = (event) => {
    if (!lowEnd && !loading) {
      event.preventDefault();
      setLoading(true);
      submitForm();
    }
  };

  const handleClickHelp = () => trackEvent(track.help);

  const handleClickListenerGoogleAuth = useCallback(() => {
    trackEvent(track.social.google.actionClick);
  }, []);

  const Title = withNoWrapWord(wordings.title, platform.platformName, { isSocialLogin });

  const handleSocialGoogleError = () => {
    setSocialGoogleError(true);
    setTimeout(() => {
      setSocialGoogleError(false);
    }, ERROR_SNACKBAR_ANIMATION_DELAY);
  };

  const loginFormClassname = classnames(
    'login-form',
    {
      'login-form--social': isSocialLogin,
    },
  );

  const loginFormActionsClassname = classnames(
    'login-form__actions',
    {
      'login-form__actions--social': isSocialLogin,
    },
  );

  const loginFormSubmitClassname = classnames(
    'login-form__submit',
    {
      'login-form__submit--social': isSocialLogin,
    },
  );

  return (
    <AndesProvider locale={locale} device={device} csrfToken={csrfToken}>
      <Page name={viewName} styles={false} state={props}>
        <Head>
          <title>{wordings.title}</title>
          <meta name="robots" content="noindex, nofollow" />
        </Head>
        <LoginGA
          navigation={navigation}
          page="ID/USER"
          errors={errors.all}
          recaptcha={recaptcha}
        />
        <CheckoutGA
          navigation={navigation}
          step="USER"
          mobile={mobile}
        />
        <Script src={urls.melidataScript} />
        <StyleAsset platformId={platform_id} viewName="identification" />
        <GridView
          headers={{ title: <Title />, mobile: device.mobile }}
          helpSection={{
            children: <SecurityProblem
              link={urls.securityProblem}
              navigation={navigation}
              platform={platform}
              wordings={wordings.securityIssues}
              track={track}
              disabled={loading}
            />,
            label: wordings.help.labelV2,
            href: platform.help.url,
            target: '_blank',
            onClick: handleClickHelp,
          }}
          modifier={isSocialLogin && 'social'}
        >
          <Form
            className={loginFormClassname}
            action={selfUrl}
            method="POST"
            noValidate
            id="login_user_form"
            onSubmit={onSubmit}
          >
            <div className="login-form__input">
              {usernameVisibility
                ? (<EmailInput
                    type="email"
                    name="user_id"
                    autocomplete
                    value={inputValue}
                    errors={inputErrorProps.errors}
                    label={wordings.labels.identification}
                    suggestions={platform.email?.suggestions || defaultSuggestions}
                    message={<InputError {...inputErrorProps} />}
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                />)
                : (<TextfieldWithMask
                    initialValue={identification}
                    id="user_id"
                    autoCapitalize="none"
                    autoComplete="new-password"
                    autoCorrect="off"
                    autoFocus
                    label={wordings.labels.identification}
                    maxLength="120"
                    message={<InputError {...inputErrorProps} />}
                    messageShow
                    mobile={mobile}
                    modifier={inputErrorProps?.errors?.length > 0 ? 'error' : 'default'}
                    name="user_id"
                    spellCheck="false"
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                />)}
              <Recaptcha {...recaptchaProps} />
            </div>
            <div className={loginFormActionsClassname}>
              <Button
                type="submit"
                loading={loading}
                onClick={onSubmit}
                className={loginFormSubmitClassname}
                srAnnouncement="Loading"
              >
                {wordings.actions.submit}
              </Button>
              {showRegistrationLink && (
                <RegistrationLink
                  disabled={loading}
                  id="registration-link"
                  text={wordings.actions.register}
                  fullWidth={false}
                  track={track.createAccount}
                  url={urls.registration}
                />
              )}
              {
                isSocialLogin && <>
                  <div className="login-form__challenge-divider">
                    <hr className="login-form__divider" aria-hidden />
                    <p className="login-form__o">{wordings.o}</p>
                    <hr className="login-form__divider" aria-hidden />
                  </div>
                  <div id="login-form__oauth-container">
                    <GoogleAuth
                      onError={handleSocialGoogleError}
                      clientId={googleSocialId}
                      navigation={{
                        ...navigation,
                        contextual: 'login',
                        client_type: 'web',
                        source: 'login',
                        go,
                        challenge_type: schemaType,
                      }}
                      buttonConfig={getGoogleAuthButtonConfig({ locale, width: googleButtonWidth, handleClickListener: handleClickListenerGoogleAuth })}
                    />
                  </div>
                </>
              }
            </div>
            <input
              type="hidden"
              name="_csrf"
              id="_csrf"
              value={csrfToken}
            />
            <input
              type="hidden"
              id="kstrs"
              name="kstrs"
              value={kstrs}
              data-testid="kstrs"
            />
            <input
              type="hidden"
              name="dps"
              id="dps"
              value={profiling.id}
            />
            <input
              type="hidden"
              id="gctkn"
              name="gctkn"
            />
            <input
              type="hidden"
              id="type"
              name="type"
              value={schemaType}
            />
          </Form>
        </GridView>
        <Snackbar
          color="red"
          className="error-snackbar"
          message={wordings.errors.server_error}
          show={promiseTimeoutError}
          delay={ERROR_SNACKBAR_ANIMATION_DELAY}
        />
        <Snackbar
          className="error-snackbar"
          message={wordings.errors.social_error}
          show={socialGoogleError}
          delay={ERROR_SNACKBAR_ANIMATION_DELAY}
        />
        <ProfilingScriptContainer profiling={profiling} />
        <ReplaceUrl url={selfUrl} />
        <DomainPreconnect {...preconnectProps} />
      </Page>
    </AndesProvider>
  );
};

Identification.propTypes = {
  csrfToken: string,
  defaultSuggestions: arrayOf(shape({
    name: string,
  })),
  device: shape({
    lowEnd: bool.isRequired,
    mobile: bool.isRequired,
  }).isRequired,
  locale: string.isRequired,
  env: shape({
    production: bool.isRequired,
    test: bool.isRequired,
  }),
  errors: shape({
    input: arrayOf(shape({
      platformId: string,
      code: string.isRequired,
      cause: string.isRequired,
      hints: string,
      text: string.isRequired,
    })),
    recaptcha: arrayOf(shape({
      platformId: string,
      code: string.isRequired,
      cause: string.isRequired,
      hints: string,
      text: string.isRequired,
    })),
    all: arrayOf(shape({
      platformId: string,
      code: string.isRequired,
      cause: string.isRequired,
      hints: string,
      text: string.isRequired,
    })),
  }),
  navigation: shape({
    login_type: string.isRequired,
    platform_id: string.isRequired,
    site_id: string.isRequired,
    parameters: shape().isRequired,
  }).isRequired,
  platform: shape().isRequired,
  promiseTimeoutError: bool,
  profiling: shape().isRequired,
  preconnectProps: shape({ urls: arrayOf(string) }).isRequired,
  recaptcha: shape({
    action: string.isRequired,
    key: string,
    lang: string.isRequired,
    required: bool.isRequired,
  }).isRequired,
  showRegistrationLink: bool.isRequired,
  schemaType: string.isRequired,
  track: shape({
    id: string,
    help: shape({
      challenge: string,
      source: string,
      tracking_id: string,
      version: string,
    }),
    securityProblem: shape({
      challenge: string,
      tracking_id: string,
      code: string,
    }),
    social: shape({
      google: shape({
        actionClick: shape({
          tracking_id: string,
          source: string,
          social_app: string,
        }),
      }),
    }),
  }),
  urls: shape({
    melidataScript: string.isRequired,
    selfUrl: string.isRequired,
    apiCallbackUrl: string.isRequired,
    incompleteRegistrationUrl: string,
    registration: string.isRequired,
  }).isRequired,
  user: shape({
    identification: string,
    usernameVisibility: bool,
  }).isRequired,
  viewMode: string.isRequired,
  contentConfiguration: shape({
    isSocialLogin: bool.isRequired,
  }),
  viewName: string.isRequired,
  wordings: shape({
    title: string.isRequired,
    errors: shape({
      email_not_found: string.isRequired,
      nickname_not_found: string.isRequired,
      phone_not_found: string.isRequired,
      missing_response: string.isRequired,
      user_required: string.isRequired,
      user_incomplete_registration: string.isRequired,
      captcha_word_required: string.isRequired,
      captcha_response_invalid: string.isRequired,
      invalid_user_site: string.isRequired,
      user_site_platform_mismatch: string.isRequired,
      operator_not_allowed: string.isRequired,
      server_error: string.isRequired,
    }).isRequired,
    help: shape({
      label: string.isRequired,
      title: string.isRequired,
    }),
    actions: shape({
      submit: string.isRequired,
      register: string.isRequired,
    }).isRequired,
    labels: shape({
      identification: string.isRequired,
    }),
  }).isRequired,
  googleButtonWidth: string.isRequired,
  googleSocialId: string.isRequired,
};

export default Identification;
