import { LoggerFactory } from 'nordic/logger';

const logger = LoggerFactory('melidata-track');

/**
 * @typedef LoginAuthChallengeEventData
 * @prop  {String} challenge
 * @prop  {String} tracking_id
 * @prop  {String} source
 * @prop  {String} [reauthentication]
 * @prop  {String} [version]
 * @prop  {String} [code]
 */

/**
 * @param  {Object} data
 * @param  {string} data.path
 * @param  {Object} data.event_data
 */

export const trackEvent = (data) => {
  try {
    if (typeof melidata === 'function') {
      // eslint-disable-next-line no-undef
      melidata('send', 'event', data);
    }
  } catch (e) {
    logger.warn(`Error sending track for Melidata: ${e}`);
  }
};

